import React, { Component } from 'react';
import { Col, Container, Row } from 'reactstrap';
import Pageheading from '../../widgets/pageheading';

class privacypolicy extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        window.scrollTo(0, 0)
    }
    render() {
        return (
            <>
                {/*hero section start*/}
                <section className="bg-light">
                    <Pageheading foldername={"Pages"} title={"Privacy Policy"}  />
                </section>
                {/*hero section end*/}
                {/*body content start*/}
                <div className="page-content">
                    {/*privacy start*/}
                    <section>
                        <Container>
                            <Row>
                                <div className="col-lg-12 col-md-12">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem error quae illo excepturi nostrum blanditiis laboriosam magnam explicabo.</p>
                                    <p>eum nihil expedita dolorum odio dolorem, explicabo rem illum magni perferendis. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem error quae illo excepturi nostrum blanditiis laboriosam magnam explicabo. Molestias, eum nihil expedita dolorum odio dolorem, explicabo rem illum magni perferendis.</p>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quidem error quae illo excepturi nostrum blanditiis laboriosam magnam explicabo. Molestias, eum nihil expedita dolorum odio dolorem, explicabo rem illum magni perferendis.</p>
                                    <h4 className="mt-5 text-primary">Personal Information</h4>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="badge-primary-soft rounded p-1">
                                            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                                <polyline points="20 6 9 17 4 12" />
                                            </svg>
                                        </div>
                                        <p className="mb-0 ml-3">Lorem ipsum dolor sit amet, consectetur</p>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="badge-primary-soft rounded p-1">
                                            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                                <polyline points="20 6 9 17 4 12" />
                                            </svg>
                                        </div>
                                        <p className="mb-0 ml-3">Quidem error quae illo excepturi nostrum blanditiis laboriosam</p>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="badge-primary-soft rounded p-1">
                                            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                                <polyline points="20 6 9 17 4 12" />
                                            </svg>
                                        </div>
                                        <p className="mb-0 ml-3">Molestias, eum nihil expedita dolorum odio dolorem</p>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="badge-primary-soft rounded p-1">
                                            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                                <polyline points="20 6 9 17 4 12" />
                                            </svg>
                                        </div>
                                        <p className="mb-0 ml-3">Eum nihil expedita dolorum odio dolorem</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="badge-primary-soft rounded p-1">
                                            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                                <polyline points="20 6 9 17 4 12" />
                                            </svg>
                                        </div>
                                        <p className="mb-0 ml-3">Explicabo rem illum magni perferendis</p>
                                    </div>
                                    <p className="mt-5">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, ex, quisquam. Nulla excepturi sint iusto incidunt sed omnis expedita, commodi dolores. Debitis nemo animi quia deleniti commodi nesciunt illo. Deserunt.</p>
                                    <h4 className="mt-5 text-primary">Use of User Information.</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, ex, quisquam. Nulla excepturi sint iusto incidunt sed omnis expedita, commodi dolores. Debitis nemo animi quia deleniti commodi nesciunt illo. Deserunt.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, ex, quisquam. Nulla excepturi sint iusto incidunt sed omnis expedita, commodi dolores. Debitis nemo animi quia deleniti commodi nesciunt illo. Deserunt.Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, ex, quisquam. Nulla excepturi sint iusto incidunt sed omnis expedita, commodi dolores. Debitis nemo animi quia deleniti commodi nesciunt illo. Deserunt.</p>
                                    <h4 className="mt-5 text-primary">Disclosure of User Information.</h4>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem ullam nostrum dolor alias aspernatur nobis suscipit eaque cumque distinctio eos, beatae deserunt, nihil nam maiores vero, eius harum. Reprehenderit, aspernatur.</p>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="badge-primary-soft rounded p-1">
                                            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                                <polyline points="20 6 9 17 4 12" />
                                            </svg>
                                        </div>
                                        <p className="mb-0 ml-3">Lorem ipsum dolor sit amet, consectetur</p>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="badge-primary-soft rounded p-1">
                                            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                                <polyline points="20 6 9 17 4 12" />
                                            </svg>
                                        </div>
                                        <p className="mb-0 ml-3">Quidem error quae illo excepturi nostrum blanditiis laboriosam</p>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="badge-primary-soft rounded p-1">
                                            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                                <polyline points="20 6 9 17 4 12" />
                                            </svg>
                                        </div>
                                        <p className="mb-0 ml-3">Molestias, eum nihil expedita dolorum odio dolorem</p>
                                    </div>
                                    <div className="d-flex align-items-center mb-3">
                                        <div className="badge-primary-soft rounded p-1">
                                            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                                <polyline points="20 6 9 17 4 12" />
                                            </svg>
                                        </div>
                                        <p className="mb-0 ml-3">Eum nihil expedita dolorum odio dolorem</p>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <div className="badge-primary-soft rounded p-1">
                                            <svg width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-check">
                                                <polyline points="20 6 9 17 4 12" />
                                            </svg>
                                        </div>
                                        <p className="mb-0 ml-3">Explicabo rem illum magni perferendis</p>
                                    </div>
                                    <p className="mt-5 mb-0">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Possimus, ex, quisquam. Nulla excepturi sint iusto incidunt sed omnis expedita, commodi dolores. Debitis nemo animi quia deleniti commodi nesciunt illo. Deserunt.</p>
                                </div>
                            </Row>
                        </Container>
                    </section>
                    {/*privacy end*/}
                </div>
                {/*body content end*/}
            </>
        );
    }
}

export default privacypolicy;