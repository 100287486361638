/**
 *  Product List
 */

export default [
    {
        "id": 1,
        "name": "Women Lather Jacket",
        "pictures": ["product/main/01.jpg", "product/main/02.jpg"],
        "stock": 32,
        "price": 3500,
        "discount": 1000,
        "salePrice": 2500,
        "description": "Adjustable and removable wide shoulder strap. Lined interior. Magnetic clasp closure.",
        "rating": 5,
        "tags": ["Women"],
        "size": ["M", "XL"],
        "category": "Women",
        "colors": ["Red"]
    },
    {
        "id": 2,
        "name": "Women Sweater",
        "pictures": ["product/main/03.jpg", "product/main/04.jpg"],
        "stock": 32,
        "price": 2400,
        "discount": 600,
        "salePrice": 1800,
        "description": "Burgundy mini crossbody bag. Oval shape. Adjustable and removable wide shoulder strap. Lined interior. Magnetic clasp closure.",
        "rating": 5,
        "tags": ["Women"],
        "size": ["M", "XL"],
        "category": "Women",
        "colors": ["Purple"]
    },
    {
        "id": 3,
        "name": "Gold Framed Sunglasses",
        "pictures": ["product/main/05.jpg", "product/main/06.jpg"],
        "stock": 64,
        "price": 1200,
        "discount": 300,
        "salePrice": 900,
        "description": "Versatile gold unisex sunglasses from Sundown, that offer protection from harmful UV rays while rendering a comfortable and stylish fit SIZE GUIDE",
        "rating": 5,
        "tags": ["Men"],
        "size": ["54"],
        "category": "Men",
        "colors": ["Gold"]
    },
    {
        "id": 4,
        "name": "Unpaired Running Shoe",
        "pictures": ["product/main/07.jpg", "product/main/08.jpg"],
        "stock": 18,
        "price": 3200,
        "discount": 600,
        "salePrice": 2600,
        "description": "Unpaired Running Shoe",
        "rating": 5,
        "tags": ["Shoes"],
        "size": ["M", "XL"],
        "category": "Men",
        "colors": ["White"]
    },
    {
        "id": 5,
        "name": "Luxury Fashion Bag",
        "pictures": ["product/main/09.jpg", "product/main/10.jpg"],
        "stock": 18,
        "price": 1900,
        "discount": 600,
        "salePrice": 1300,
        "description": "Burgundy mini crossbody bag. Oval shape. Adjustable and removable wide shoulder strap. Lined interior. Magnetic clasp closure.",
        "rating": 3,
        "tags": ["Women", "Bags"],
        "size": ["M", "XL"],
        "category": "Women",
        "colors": ["Purple"]
    },

    {
        "id": 6,
        "name": "Bright Hipster Cloth",
        "pictures": ["product/main/11.jpg", "product/main/12.jpg"],
        "stock": 18,
        "price": 3200,
        "discount": 600,
        "salePrice": 2600,
        "description": "Burgundy mini crossbody bag. Oval shape. Adjustable and removable wide shoulder strap. Lined interior. Magnetic clasp closure.",
        "rating": 5,
        "tags": ["Women"],
        "size": ["M", "XL"],
        "category": "Women",
        "colors": ["Purple"]
    },
    {
        "id": 7,
        "name": "Men's Brand Tshirts",
        "pictures": ["product/main/13.jpg", "product/main/14.jpg"],
        "stock": 18,
        "price": 1800,
        "discount": 600,
        "salePrice": 1200,
        "description": "Slim fit textured smart shirt featuring a regular collar,long sleeves with buttoned cuffs,a button-up front hidden by a placket and a label appliqu on the inside of the hem.",
        "rating": 5,
        "tags": ["Men"],
        "size": ["M", "XL"],
        "category": "Men",
        "colors": ["Black"]
    },
    {
        "id": 8,
        "name": "Crewneck Sweatshirt",
        "pictures": ["product/main/15.jpg", "product/main/16.jpg"],
        "stock": 18,
        "price": 2600,
        "discount": 600,
        "salePrice": 2200,
        "description": "Burgundy mini crossbody bag. Oval shape. Adjustable and removable wide shoulder strap. Lined interior. Magnetic clasp closure.",
        "rating": 5,
        "tags": ["Men"],
        "size": ["M", "XL"],
        "category": "Men",
        "colors": ["Red"]
    },
    {
        "id": 9,
        "name": "iphone 11 Pro",
        "pictures": ["electronic/product/04.jpg", "electronic/product/04.jpg"],
        "stock": 18,
        "price": 1900,
        "discount": 600,
        "salePrice": 1300,
        "description": "iPhone 11 Pro smartphone was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
        "rating": 5,
        "tags": ["Electronic"],
        "size": ["12"],
        "category": "Electronic",
        "colors": ["Black"]
    },
    {
        "id": 10,
        "name": "Film Roll And Kodak",
        "pictures": ["electronic/product/03.jpg", "electronic/product/03.jpg"],
        "stock": 18,
        "price": 1200,
        "discount": 300,
        "salePrice": 900,
        "description": "Film Roll And Kodak smartphone was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
        "rating": 5,
        "tags": ["Electronic"],
        "size": ["12"],
        "category": "Electronic",
        "colors": ["Orange"]
    },
    {
        "id": 11,
        "name": "Amazon Echo Speaker",
        "pictures": ["electronic/product/01.jpg", "electronic/product/01.jpg"],
        "stock": 18,
        "price": 1900,
        "discount": 600,
        "salePrice": 1300,
        "description": "Amazon Echo Speaker smartphone was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
        "rating": 5,
        "tags": ["Electronic"],
        "size": ["12"],
        "category": "Electronic",
        "colors": ["White"]
    },
    {
        "id": 12,
        "name": "Canon EOS EF 75",
        "pictures": ["electronic/product/02.jpg", "electronic/product/02.jpg"],
        "stock": 18,
        "price": 2400,
        "discount": 600,
        "salePrice": 1800,
        "description": "Canon EOS EF 75 was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
        "rating": 5,
        "tags": ["Electronic"],
        "size": ["12"],
        "category": "Electronic",
        "colors": ["Black"]
    },
    {
        "id": 13,
        "name": "Headphone Amplifier",
        "pictures": ["electronic/product/05.jpg", "electronic/product/05.jpg"],
        "stock": 18,
        "price": 1200,
        "discount": 300,
        "salePrice": 900,
        "description": "Headphone Amplifier was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
        "rating": 5,
        "tags": ["Electronic"],
        "size": ["12"],
        "category": "Electronic",
        "colors": ["Black"]
    },
    {
        "id": 14,
        "name": "Bright Hipster Cloth",
        "pictures": ["electronic/product/06.jpg", "electronic/product/06.jpg"],
        "stock": 18,
        "price": 1200,
        "discount": 300,
        "salePrice": 900,
        "description": "Bright Hipster Cloth was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
        "rating": 5,
        "tags": ["Electronic"],
        "size": ["12"],
        "category": "Electronic",
        "colors": ["Black"]
    },
    {
        "id": 15,
        "name": "Women Speaker",
        "pictures": ["electronic/product/07.jpg", "electronic/product/07.jpg"],
        "stock": 18,
        "price": 1900,
        "discount": 600,
        "salePrice": 1300,
        "description": "Women Speaker was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
        "rating": 5,
        "tags": ["Electronic"],
        "size": ["12"],
        "category": "Electronic",
        "colors": ["White"]
    },
    {
        "id": 16,
        "name": "Men's Speaker",
        "pictures": ["electronic/product/08.jpg", "electronic/product/08.jpg"],
        "stock": 18,
        "price": 4700,
        "discount": 600,
        "salePrice": 1300,
        "description": "Men's Speaker was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
        "rating": 5,
        "tags": ["Electronic"],
        "size": ["12"],
        "category": "Electronic",
        "colors": ["White"]
    },
    {
        "id": 17,
        "name": "Leather Chair",
        "pictures": ["furniture/product/01.jpg", "furniture/product/02.jpg"],
        "stock": 18,
        "price": 3500,
        "discount": 1000,
        "salePrice": 2500,
        "description": "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
        "rating": 5,
        "tags": ["Furniture"],
        "size": ["12"],
        "category": "Furniture",
        "colors": ["Gold"]
    },
    {
        "id": 18,
        "name": "Luxury Royal Chair",
        "pictures": ["furniture/product/03.jpg", "furniture/product/04.jpg"],
        "stock": 18,
        "price": 2400,
        "discount": 400,
        "salePrice": 1800,
        "description": "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
        "rating": 5,
        "tags": ["Furniture"],
        "size": ["12"],
        "category": "Furniture",
        "colors": ["Gold"]
    },
    {
        "id": 19,
        "name": "Brown Study Table Chair",
        "pictures": ["furniture/product/06.jpg", "furniture/product/05.jpg"],
        "stock": 18,
        "price": 1200,
        "discount": 300,
        "salePrice": 900,
        "description": "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
        "rating": 5,
        "tags": ["Furniture"],
        "size": ["12"],
        "category": "Furniture",
        "colors": ["Gold"]
    },
    {
        "id": 20,
        "name": "Red Royal Chair",
        "pictures": ["furniture/product/07.jpg", "furniture/product/08.jpg"],
        "stock": 18,
        "price": 3200,
        "discount": 1000,
        "salePrice": 2600,
        "description": "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
        "rating": 5,
        "tags": ["Furniture"],
        "size": ["12"],
        "category": "Furniture",
        "colors": ["Red"]
    },
    {
        "id": 21,
        "name": "Dining Chairs",
        "pictures": ["furniture/product/09.jpg", "furniture/product/10.jpg"],
        "stock": 18,
        "price": 1900,
        "discount": 500,
        "salePrice": 1300,
        "description": "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
        "rating": 5,
        "tags": ["Furniture"],
        "size": ["12"],
        "category": "Furniture",
        "colors": ["Green"]
    },
    {
        "id": 22,
        "name": "Bright Hipster Cloth",
        "pictures": ["furniture/product/11.jpg", "furniture/product/12.jpg"],
        "stock": 18,
        "price": 4200,
        "discount": 500,
        "salePrice": 3700,
        "description": "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
        "rating": 5,
        "tags": ["Furniture"],
        "size": ["12"],
        "category": "Furniture",
        "colors": ["Blue"]
    },
    {
        "id": 23,
        "name": "Comfortable Armchair",
        "pictures": ["furniture/product/14.jpg", "furniture/product/15.jpg"],
        "stock": 18,
        "price": 3500,
        "discount": 1000,
        "salePrice": 2500,
        "description": "Comfortable Armchair was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
        "rating": 5,
        "tags": ["Furniture"],
        "size": ["12"],
        "category": "Furniture",
        "colors": ["Gold"]
    },
    {
        "id": 24,
        "name": "Modern Armchair",
        "pictures": ["furniture/product/13.jpg", "furniture/product/13.jpg"],
        "stock": 18,
        "price": 1800,
        "discount": 300,
        "salePrice": 1500,
        "description": "Modern Armchair was launched on 10th September 2019. The phone comes with a 5.80-inch touchscreen display with a resolution of 1125x2436 pixels ",
        "rating": 5,
        "tags": ["Furniture"],
        "size": ["12"],
        "category": "Furniture",
        "colors": ["Green"]
    },
    {
        "id": 25,
        "name": "Teddy Stuffed Bear",
        "pictures": ["kids/product/01.jpg", "kids/product/02.jpg"],
        "stock": 18,
        "price": 3500,
        "discount": 1000,
        "salePrice": 2500,
        "description": "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
        "rating": 5,
        "tags": ["Kids"],
        "size": ["12"],
        "category": "Kids",
        "colors": ["Gold"]
    },
    {
        "id": 26,
        "name": "Close up of Toy Blocks",
        "pictures": ["kids/product/03.jpg", "kids/product/04.jpg"],
        "stock": 18,
        "price": 4200,
        "discount": 500,
        "salePrice": 3700,
        "description": "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
        "rating": 5,
        "tags": ["Kids"],
        "size": ["12"],
        "category": "Kids",
        "colors": ["Gold"]
    },
    {
        "id": 27,
        "name": "Little Girl With Teddy ",
        "pictures": ["kids/product/05.jpg", "kids/product/06.jpg"],
        "stock": 18,
        "price": 1800,
        "discount": 300,
        "salePrice": 1500,
        "description": "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
        "rating": 5,
        "tags": ["Kids"],
        "size": ["12"],
        "category": "Kids",
        "colors": ["Blue"]
    },
    {
        "id": 28,
        "name": "Easter Rabbit Toys",
        "pictures": ["kids/product/07.jpg", "kids/product/08.jpg"],
        "stock": 18,
        "price": 1900,
        "discount": 600,
        "salePrice": 1300,
        "description": "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
        "rating": 5,
        "tags": ["Kids"],
        "size": ["12"],
        "category": "Kids",
        "colors": ["Blue"]
    },
    {
        "id": 29,
        "name": "Baby Mittens & Toys",
        "pictures": ["kids/product/09.jpg", "kids/product/10.jpg"],
        "stock": 18,
        "price": 1900,
        "discount": 500,
        "salePrice": 1300,
        "description": "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
        "rating": 5,
        "tags": ["Kids"],
        "size": ["12"],
        "category": "Kids",
        "colors": ["Pink"]
    },
    {
        "id": 30,
        "name": "Toy Plane With Paper",
        "pictures": ["kids/product/13.jpg", "kids/product/14.jpg"],
        "stock": 18,
        "price": 4200,
        "discount": 500,
        "salePrice": 3700,
        "description": "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
        "rating": 5,
        "tags": ["Kids"],
        "size": ["12"],
        "category": "Kids",
        "colors": ["Pink"]
    },
    {
        "id": 31,
        "name": "Stuffed Animal Toy",
        "pictures": ["kids/product/15.jpg", "kids/product/16.jpg"],
        "stock": 18,
        "price": 3500,
        "discount": 1000,
        "salePrice": 2500,
        "description": "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
        "rating": 5,
        "tags": ["Kids"],
        "size": ["12"],
        "category": "Kids",
        "colors": ["Pink"]
    },
    {
        "id": 32,
        "name": "Baby Playing Toys",
        "pictures": ["kids/product/17.jpg", "kids/product/18.jpg"],
        "stock": 18,
        "price": 1800,
        "discount": 300,
        "salePrice": 1500,
        "description": "12 months domestic warranty. We will provide replacement of particular damaged parts only or entire product",
        "rating": 5,
        "tags": ["Kids"],
        "size": ["12"],
        "category": "Kids",
        "colors": ["Pink"]
    },

    {
        "id": 33,
        "name": "OVAL MINI CROSSBODY BAG",
        "pictures": ["product/bags/bag-01-front.jpg", "product/bags/bag-01-back.jpg"],
        "stock": 32,
        "price": 2290,
        "discount": 500,
        "salePrice": 1790,
        "description": "Burgundy mini crossbody bag. Oval shape. Adjustable and removable wide shoulder strap. Lined interior. Magnetic clasp closure.",
        "rating": 3,
        "tags": ["Women", "Bags"],
        "size": ["M", "XL"],
        "category": "Women",
        "colors": ["Purple"]
    },
    {
        "id": 34,
        "name": "TIE-DYE FABRIC BUCKET BAG",
        "pictures": ["product/bags/bag-02-front.jpg", "product/bags/bag-02-back.jpg"],
        "stock": 46,
        "price": 2290,
        "discount": 500,
        "salePrice": 1790,
        "description": "Bucket bag in a combination of colours. Tie-dye print on the body. Front pocket. Shoulder straps. Two pockets in lining. Zip closure.",
        "rating": 4,
        "tags": ["Women", "Bags"],
        "size": ["M", "XL"],
        "category": "Women",
        "colors": ["Gray", "Red"]
    },
    {
        "id": 35,
        "name": "CANVAS TOTE BAG",
        "pictures": ["product/bags/bag-03-front.jpg", "product/bags/bag-03-back.jpg"],
        "stock": 20,
        "price": 2290,
        "discount": 500,
        "salePrice": 1790,
        "description": "Beige tote bag. Made of natural materials. Canvas exterior. Contrast-coloured trims. Handles with slogan zip pulls. Lined interior with zip pocket.",
        "rating": 4,
        "tags": ["Women", "Bags"],
        "size": ["M", "XL"],
        "category": "Women",
        "colors": ["Gray"]
    },
    {
        "id": 36,
        "name": "BRAIDED CROSSBODY BAG",
        "pictures": ["product/bags/bag-04-front.jpg", "product/bags/bag-04-back.jpg"],
        "stock": 15,
        "price": 2990,
        "discount": 700,
        "salePrice": 2290,
        "description": "BLACK crossbody bag. Braided exterior featuring decorative seashells,a chain trim and fringing around the front flap. Chain shoulder strap. Lined interior with pocket. Magnetic clasp closure.",
        "rating": 2,
        "tags": ["Women", "Bags"],
        "size": ["M", "XL"],
        "category": "Women",
        "colors": ["Black"]
    },
    {
        "id": 37,
        "name": "CROSSBODY BAG",
        "pictures": ["product/bags/bag-05-front.jpg", "product/bags/bag-05-back.jpg"],
        "stock": 5,
        "price": 2290,
        "discount": 500,
        "salePrice": 1790,
        "description": "Crossbody bag in a combination of colours. Made of natural materials. Jute exterior in contrast materials with a polka dot print. Wooden kiss-lock frame",
        "rating": 3,
        "tags": ["Women", "Bags"],
        "size": ["M", "XL"],
        "category": "Women",
        "colors": ["Beige"]
    },
    {
        "id": 38,
        "name": "MARBLE-EFFECT MINAUDIRE",
        "pictures": ["product/bags/bag-06-front.jpg", "product/bags/bag-06-back.jpg"],
        "stock": 12,
        "price": 3990,
        "discount": 1000,
        "salePrice": 2990,
        "description": "Minaudire in a combination of colours. Marble-effect exterior with circular patterns. Metal detail on the contour. Chain shoulder strap. Lined interior. Metal clasp closure.",
        "rating": 5,
        "tags": ["Women", "Bags"],
        "size": ["M", "XL"],
        "category": "Women",
        "colors": ["Pink"]
    },
    {
        "id": 39,
        "name": "NATURAL GLOSSY BRAIDED CROSSBODY BAG",
        "pictures": ["product/bags/bag-07-front.jpg", "product/bags/bag-07-back.jpg"],
        "stock": 23,
        "price": 2890,
        "discount": 600,
        "salePrice": 2290,
        "description": "Red crossbody bag made of natural materials. Braided body with a glossy finish. Gold metal detail on the front. Shoulder strap. Lined interior. Magnetic clasp with strap closure.",
        "rating": 2,
        "tags": ["Women", "Bags"],
        "size": ["M", "XL"],
        "category": "Women",
        "colors": ["Red"]
    },
    {
        "id": 40,
        "name": "NATURAL MULTICOLOURED WOVEN HANDBAG",
        "pictures": ["product/bags/bag-08-front.jpg", "product/bags/bag-08-back.jpg"],
        "stock": 34,
        "price": 2890,
        "discount": 600,
        "salePrice": 2290,
        "description": "Multicoloured handbag. Woven body in contrast colours and materials. Metal trim along the edge. Chain shoulder strap. Lined interior. Clasp closure lined with beads.",
        "rating": 5,
        "tags": ["Women", "Bags"],
        "size": ["M", "XL"],
        "category": "Women",
        "colors": ["Red"]
    },
    {
        "id": 41,
        "name": "BLUE COLLECTION GLITTER MINI CROSSBODY BAG",
        "pictures": ["product/bags/bag-10-front.jpg", "product/bags/bag-10-back.jpg"],
        "stock": 3,
        "price": 2890,
        "discount": 600,
        "salePrice": 2290,
        "description": "Mini crossbody bag available in silver,fuchsia and BLUE. Glittery exterior. Satin-finish handles. Chain shoulder strap. Lined interior. Magnetic clasp closure.",
        "rating": 4,
        "tags": ["Women", "Bags"],
        "size": ["M", "XL"],
        "category": "Women",
        "colors": ["Blue"]
    },
    {
        "id": 42,
        "name": "FRINGED TOTE BAG",
        "pictures": ["product/bags/bag-09-front.jpg", "product/bags/bag-09-back.jpg"],
        "stock": 42,
        "price": 4990,
        "discount": 2000,
        "salePrice": 2990,
        "description": "Tan tote bag. Split suede fringing on the exterior. Shoulder straps. Lined interior with pocket. Zip closure.",
        "rating": 3,
        "tags": ["Women", "Bags"],
        "size": ["M", "XL"],
        "category": "Women",
        "colors": ["Brown"]
    },

    {
        "id": 43,
        "name": "SPORTY HOODIE WITH NEON",
        "pictures": ["product/kidsdress/dress-01-front.jpg", "product/kidsdress/dress-01-back.jpg"],
        "stock": 15,
        "price": 1590,
        "discount": 318,
        "salePrice": 1272,
        "description": "High neck sweatshirt with long sleeves. Featuring injected zip on the yoke,front pouch pocket with a slogan print and appliqu detail,and print on the back.",
        "rating": 1,
        "tags": ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
        "size": ["M", "Y"],
        "category": "Kids",
        "colors": ["Orange"]
    },
    {
        "id": 44,
        "name": "CHAMBRAY KNIT BLAZER",
        "pictures": ["product/kidsdress/dress-02-front.jpg", "product/kidsdress/dress-02-back.jpg"],
        "stock": 12,
        "price": 2790,
        "discount": 558,
        "salePrice": 2232,
        "description": "Knit blazer with a lapel collar,V-neckline,long sleeves,button fastening and front pockets in contrasting velvet.",
        "rating": 2,
        "tags": ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
        "size": ["M", "Y"],
        "category": "Kids",
        "colors": ["Gray"]
    },
    {
        "id": 45,
        "name": "SLIM FIT STRIPED SHIRT",
        "pictures": ["product/kidsdress/dress-03-front.jpg", "product/kidsdress/dress-03-back.jpg"],
        "stock": 6,
        "price": 1590,
        "discount": 318,
        "salePrice": 1272,
        "description": "Slim fit smart shirt featuring a regular collar long sleeves with buttoned cuffs,a button-up front and a label appliqu on the inside of the hem.",
        "rating": 4,
        "tags": ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
        "size": ["M", "Y"],
        "category": "Kids",
        "colors": ["Blue"]
    },
    {
        "id": 46,
        "name": "SLIM FIT TEXTURED SHIRT",
        "pictures": ["product/kidsdress/dress-04-front.jpg", "product/kidsdress/dress-04-back.jpg"],
        "stock": 11,
        "price": 1590,
        "discount": 318,
        "salePrice": 1272,
        "description": "Slim fit textured smart shirt featuring a regular collar,long sleeves with buttoned cuffs,a button-up front hidden by a placket and a label appliqu on the inside of the hem.",
        "rating": 1,
        "tags": ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
        "size": ["M", "Y"],
        "category": "Kids",
        "colors": ["Red"]
    },
    {
        "id": 47,
        "name": " STAR WARS T-SHIRT",
        "pictures": ["product/kidsdress/dress-05-front.jpg", "product/kidsdress/dress-05-back.jpg"],
        "stock": 5,
        "price": 990,
        "discount": 198,
        "salePrice": 792,
        "description": "T-shirt with a round neckline and long sleeves. Featuring an all-over  STAR WARS print and label at the hem.",
        "rating": 5,
        "tags": ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
        "size": ["M", "Y"],
        "category": "Kids",
        "colors": ["Black"]
    },
    {
        "id": 48,
        "name": "STRIPED T-SHIRT",
        "pictures": ["product/kidsdress/dress-06-front.jpg", "product/kidsdress/dress-06-back.jpg"],
        "stock": 15,
        "price": 690,
        "discount": 138,
        "salePrice": 552,
        "description": "Round neck T-shirt featuring long sleeves,a striped print and a chest pocket.",
        "rating": 3,
        "tags": ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
        "size": ["M", "Y"],
        "category": "Kids",
        "colors": ["Green"]
    },
    {
        "id": 49,
        "name": "SEQUINNED  STAR WARS T-SHIRT",
        "pictures": ["product/kidsdress/dress-07-front.jpg", "product/kidsdress/dress-07-back.jpg"],
        "stock": 8,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "T-shirt with a round neckline and long sleeves. Featuring a  STAR WARS print on the front with reversible sequins and label at the hem.",
        "rating": 4,
        "tags": ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
        "size": ["M", "Y"],
        "category": "Kids",
        "colors": ["Green"]
    },
    {
        "id": 50,
        "name": "LENTICULAR  STAR WARS T-SHIRT",
        "pictures": ["product/kidsdress/dress-08-front.jpg", "product/kidsdress/dress-08-back.jpg"],
        "stock": 12,
        "price": 1090,
        "discount": 218,
        "salePrice": 872,
        "description": "T-shirt with a round neckline and long sleeves. Featuring a  STAR WARS print on the front with lenticular detail to create the illusion of movement",
        "rating": 2,
        "tags": ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
        "size": ["M", "Y"],
        "category": "Kids",
        "colors": ["Blue"]
    },
    {
        "id": 51,
        "name": "SPORTY T-SHIRT WITH NEON STRIPES",
        "pictures": ["product/kidsdress/dress-09-front.jpg", "product/kidsdress/dress-09-back.jpg"],
        "stock": 8,
        "price": 990,
        "discount": 198,
        "salePrice": 792,
        "description": "Long sleeve T-shirt with a round neckline. Featuring a chest pocket and a printed stripe on the front with neon detailing.",
        "rating": 2,
        "tags": ["Kids", "Coats", "Tops", "T-Shirts", "Jeans", "Trousers"],
        "size": ["M", "Y"],
        "category": "Kids",
        "colors": ["Red"]
    },
    {
        "id": 52,
        "name": "SNOW CAMOUFLAGE PRINT JACKET",
        "pictures": ["product/mandress/dress-01-front.jpg", "product/mandress/dress-01-back.jpg"],
        "stock": 8,
        "price": 3990,
        "discount": 1000,
        "salePrice": 2990,
        "description": "Jacket with an adjustable hood in the collar and long sleeves with elastic cuffs. Patch pockets with flaps and contrast taping at waist. Adjustable drawstring hem.",
        "rating": 2,
        "tags": ["Men", "Jacket", "Coats", "Blazers", "Shirts", "T-Shirts", "Jeans"],
        "size": ["S", "M", "L", "XL"],
        "category": "men",
        "colors": ["Red"]
    },
    {
        "id": 53,
        "name": "Jacket WITH POCKETS",
        "pictures": ["product/mandress/dress-02-front.jpg", "product/mandress/dress-02-back.jpg"],
        "stock": 8,
        "price": 3990,
        "discount": 1000,
        "salePrice": 2990,
        "description": "Shirt-style jacket with long sleeves and buttoned cuffs. Features a faded effect on the seams,patch pockets with flap on the chest and hips and a frayed hem.",
        "rating": 1,
        "tags": ["Men", "Jacket", "Coats", "Blazers", "Shirts", "T-Shirts", "Jeans"],
        "size": ["S", "M", "L", "XL"],
        "category": "men",
        "colors": ["Khaki"]
    },
    {
        "id": 54,
        "name": "PINSTRIPED DENIM OVERSHIRT",
        "pictures": ["product/mandress/dress-03-front.jpg", "product/mandress/dress-03-back.jpg"],
        "stock": 7,
        "price": 3990,
        "discount": 2000,
        "salePrice": 1990,
        "description": "Collared overshirt with long sleeves and buttoned cuffs. Featuring a chest patch pocket and a zip-up front.",
        "rating": 1,
        "tags": ["Men", "Jacket", "Coats", "Blazers", "Shirts", "T-Shirts", "Jeans"],
        "size": ["S", "M", "L", "XL"],
        "category": "men",
        "colors": ["Khaki"]
    },
    {
        "id": 55,
        "name": "BASIC DENIM JACKET",
        "pictures": ["product/mandress/dress-04-front.jpg", "product/mandress/dress-04-back.jpg"],
        "stock": 6,
        "price": 2790,
        "discount": 900,
        "salePrice": 1890,
        "description": "Straight,long sleeve denim jacket with a shirt collar and buttoned cuffs. Featuring a light fade effect,chest flap pockets,welt pockets at the hip and metal button fastening in the front.",
        "rating": 3,
        "tags": ["Men", "Jacket", "Coats", "Blazers", "Shirts", "T-Shirts", "Jeans"],
        "size": ["S", "M", "L", "XL"],
        "category": "men",
        "colors": ["Red"]
    },
    {
        "id": 56,
        "name": "PATCH OVERSHIRT",
        "pictures": ["product/mandress/dress-05-front.jpg", "product/mandress/dress-05-back.jpg"],
        "stock": 3,
        "price": 2790,
        "discount": 900,
        "salePrice": 1990,
        "description": "Loose-fitting collared overshirt with long sleeves and buttoned cuffs. Featuring chest patch pockets,faded effect,patch appliqus on the sleeve and button-up fastening on the front.",
        "rating": 3,
        "tags": ["Men", "Jacket", "Coats", "Blazers", "Shirts", "T-Shirts", "Jeans"],
        "size": ["S", "M", "L", "XL"],
        "category": "men",
        "colors": ["Khaki"]
    },
    {
        "id": 57,
        "name": "Jacket WITH POCKETS",
        "pictures": ["product/mandress/dress-06-front.jpg", "product/mandress/dress-06-back.jpg"],
        "stock": 5,
        "price": 4990,
        "discount": 1500,
        "salePrice": 3490,
        "description": "Jacket with a high collar,zip detail and a smooth-effect wash. Featuring long sleeves with buttoned cuffs,front patch pockets with flaps on the chest and hips and front button fastening.",
        "rating": 4,
        "tags": ["Men", "Jacket", "Coats", "Blazers", "Shirts", "T-Shirts", "Jeans"],
        "size": ["S", "M", "L", "XL"],
        "category": "men",
        "colors": ["Khaki"]
    },
    {
        "id": 58,
        "name": "COACH JACKET",
        "pictures": ["product/mandress/dress-07-front.jpg", "product/mandress/dress-07-back.jpg"],
        "stock": 4,
        "price": 3990,
        "discount": 1000,
        "salePrice": 2990,
        "description": "Padded jacket with a shirt collar,long sleeves and buttoned cuffs. Featuring combined mesh lining with pocket detail,front welt pockets and front snap-button fastening.",
        "rating": 5,
        "tags": ["Men", "Jacket", "Coats", "Blazers", "Shirts", "T-Shirts", "Jeans"],
        "size": ["S", "M", "L", "XL"],
        "category": "men",
        "colors": ["Black"]
    },
    {
        "id": 59,
        "name": "TEXTURED WORKER JACKET",
        "pictures": ["product/mandress/dress-08-front.jpg", "product/mandress/dress-08-back.jpg"],
        "stock": 3,
        "price": 3990,
        "discount": 1000,
        "salePrice": 2990,
        "description": "Long sleeve lightweight jacket with collar. Featuring front patch pockets on chest and hips,contrasting topstitching and metal button fastening in the front.",
        "rating": 4,
        "tags": ["Men", "Jacket", "Coats", "Blazers", "Shirts", "T-Shirts", "Jeans"],
        "size": ["S", "M", "L", "XL"],
        "category": "men",
        "colors": ["Khaki"]
    },
    {
        "id": 60,
        "name": "FAUX SUEDE OVERSHIRT",
        "pictures": ["product/mandress/dress-09-front.jpg", "product/mandress/dress-09-back.jpg"],
        "stock": 6,
        "price": 3990,
        "discount": 1000,
        "salePrice": 2990,
        "description": "Long sleeve overshirt with a regular collar and buttoned cuffs. Featuring a chest patch pocket,welt pockets at the hip and snap-button fastening at the front hidden by a placket.",
        "rating": 5,
        "tags": ["Men", "Jacket", "Coats", "Blazers", "Shirts", "T-Shirts", "Jeans"],
        "size": ["S", "M", "L", "XL"],
        "category": "men",
        "colors": ["Khaki"]
    },

    {
        "id": 61,
        "name": "HIGH NECK COAT WITH BELT",
        "pictures": ["product/womandress/dress-01-front.jpg", "product/womandress/dress-01-back.jpg"],
        "stock": 84,
        "price": 7990,
        "discount": 3000,
        "salePrice": 4990,
        "description": "High neck coat in a wool blend. Featuring long sleeves,front pockets,a belt in matching fabric and button fastening at the front.",
        "rating": 3,
        "tags": ["Coats", "Jackets", "Dresses", "Jumpsuits", "Tops", "Shirts", "Jeans", "Shorts", "Trousers", "T-Shirts"],
        "size": ["XS", "S", "M", "L", "XL"],
        "category": "Women",
        "colors": ["Black"]
    },
    {
        "id": 62,
        "name": "CROPPED JACKET WITH SHOULDER PADS",
        "pictures": ["product/womandress/dress-02-front.jpg", "product/womandress/dress-02-back.jpg"],
        "stock": 128,
        "price": 2790,
        "discount": 800,
        "salePrice": 1990,
        "description": "Cropped denim collared jacket with long sleeves,shoulder pads and metal button fastening in the front.",
        "rating": 4,
        "tags": ["Coats", "Jackets", "Dresses", "Jumpsuits", "Tops", "Shirts", "Jeans", "Shorts", "Trousers", "T-Shirts"],
        "size": ["XS", "S", "M", "L", "XL"],
        "category": "Women",
        "colors": ["Blue"]
    },
    {
        "id": 63,
        "name": "MASCULINE COAT WITH COMBINED FABRIC",
        "pictures": ["product/womandress/dress-03-front.jpg", "product/womandress/dress-03-back.jpg"],
        "stock": 234,
        "price": 79990,
        "discount": 4000,
        "salePrice": 3990,
        "description": "Coat with a lapel collar and long sleeves. Featuring front patch pockets with flaps,inside buttoned welt pocket,combined fabric detail,a back vent at the hem and front button fastening.",
        "rating": 5,
        "tags": ["Coats", "Jackets", "Dresses", "Jumpsuits", "Tops", "Shirts", "Jeans", "Shorts", "Trousers", "T-Shirts"],
        "size": ["XS", "S", "M", "L", "XL"],
        "category": "Women",
        "colors": ["Black"]
    },
    {
        "id": 64,
        "name": "2-IN-1 DOWN PUFFER JACKET AND REGULAR JACKET",
        "pictures": ["product/womandress/dress-04-front.jpg", "product/womandress/dress-04-back.jpg"],
        "stock": 94,
        "price": 8990,
        "discount": 4000,
        "salePrice": 4990,
        "description": "Regular jacket featuring a high neck with an inner hood,long sleeves with adjustable hook-and-loop tabs on the cuffs,front patch pockets with snap-button flaps,reflective strips on the pockets and on the back.",
        "rating": 5,
        "tags": ["Coats", "Jackets", "Dresses", "Jumpsuits", "Tops", "Shirts", "Jeans", "Shorts", "Trousers", "T-Shirts"],
        "size": ["XS", "S", "M", "L", "XL"],
        "category": "Women",
        "colors": ["Red", "Black"]
    },
    {
        "id": 65,
        "name": "BUTTONED MASCULINE COAT",
        "pictures": ["product/womandress/dress-05-front.jpg", "product/womandress/dress-05-back.jpg"],
        "stock": 62,
        "price": 8990,
        "discount": 3000,
        "salePrice": 5990,
        "description": "Long sleeve coat with a lapel collar. Featuring front flap pockets and button fastening.",
        "rating": 2,
        "tags": ["Coats", "Jackets", "Dresses", "Jumpsuits", "Tops", "Shirts", "Jeans", "Shorts", "Trousers", "T-Shirts"],
        "size": ["XS", "S", "M", "L", "XL"],
        "category": "Women",
        "colors": ["Gray"]
    },
    {
        "id": 66,
        "name": "CONTRAST PUFFER JACKET",
        "pictures": ["product/womandress/dress-06-front.jpg", "product/womandress/dress-06-back.jpg"],
        "stock": 13,
        "price": 4990,
        "discount": 2000,
        "salePrice": 2990,
        "description": "Jacket featuring a high neck,an adjustable hood,matching long sleeves with elastic cuffs,front welt pockets,an adjustable matching hem and a zip-up front hidden by a snap-button placket.",
        "rating": 3,
        "tags": ["Coats", "Jackets", "Dresses", "Jumpsuits", "Tops", "Shirts", "Jeans", "Shorts", "Trousers", "T-Shirts"],
        "size": ["XS", "S", "M", "L", "XL"],
        "category": "Women",
        "colors": ["Green"]
    },
    {
        "id": 67,
        "name": "DENIM VEST",
        "pictures": ["product/womandress/dress-07-front.jpg", "product/womandress/dress-07-back.jpg"],
        "stock": 8,
        "price": 2590,
        "discount": 600,
        "salePrice": 1990,
        "description": "Short sleeveless waistcoat with a collar. Featuring front patch pockets with flaps and metal button fastening at the front.",
        "rating": 4,
        "tags": ["Coats", "Jackets", "Dresses", "Jumpsuits", "Tops", "Shirts", "Jeans", "Shorts", "Trousers", "T-Shirts"],
        "size": ["XS", "S", "M", "L", "XL"],
        "category": "Women",
        "colors": ["Blue"]
    },
    {
        "id": 68,
        "name": "MASCULINE HERRINGBONE COAT",
        "pictures": ["product/womandress/dress-08-front.jpg", "product/womandress/dress-08-back.jpg"],
        "stock": 53,
        "price": 8990,
        "discount": 3000,
        "salePrice": 5990,
        "description": "Coat made in a wool blend. Featuring a lapel collar,long sleeves,pronounced shoulders,jetted front pockets and double-breasted front button fastening.",
        "rating": 5,
        "tags": ["Coats", "Jackets", "Dresses", "Jumpsuits", "Tops", "Shirts", "Jeans", "Shorts", "Trousers", "T-Shirts"],
        "size": ["XS", "S", "M", "L", "XL"],
        "category": "Women",
        "colors": ["Gray"]
    },
    {
        "id": 69,
        "name": "BUTTONED WAISTCOAT DRESS",
        "pictures": ["product/womandress/dress-09-front.jpg", "product/womandress/dress-09-back.jpg"],
        "stock": 47,
        "price": 3990,
        "discount": 1000,
        "salePrice": 2990,
        "description": "Sleeveless waistcoat with a lapel collar and V-neckline. Featuring front flap pockets and a double-breasted button fastening.",
        "rating": 3,
        "tags": ["Coats", "Jackets", "Dresses", "Jumpsuits", "Tops", "Shirts", "Jeans", "Shorts", "Trousers", "T-Shirts"],
        "size": ["XS", "S", "M", "L", "XL"],
        "category": "Women",
        "colors": ["Blue"]
    },
]